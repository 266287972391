@import "../../_variable";

// .sidebar_item {
// 	background-color: white;
// 	width: 476px;
// 	height: 100%;
// 	background-color: rgba($color: white, $alpha: 0.8);

// 	&.hamak {
// 		-webkit-box-shadow: -6px 4px 12px 0px #00acec;
// 		box-shadow: -6px 4px 12px 0px #00acec;

// 		.sidebar_content {
// 			input,
// 			a {
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				gap: 12px;
// 				background-color: #31a459;

// 				&.close {
// 					background-color: #00acec;
// 				}
// 			}

// 			.tag_container {
// 				li {
// 					background-color: #31a459;
// 				}
// 			}

// 			.icon_close {
// 				fill: #00acec;
// 			}

// 			h1 {
// 				color: #00acec;
// 			}
// 		}
// 	}

// 	-webkit-box-shadow: -6px 4px 12px 0px #eb6c41;
// 	box-shadow: -6px 4px 12px 0px #eb6c41;
// 	border-radius: 30px 0 0 30px;
// 	padding: 40px;
// 	overflow: hidden;

// 	.sidebar_content {
// 		height: 100%;
// 		width: 100%;
// 		display: flex;
// 		justify-content: space-between;
// 		align-items: center;
// 		flex-direction: column;

// 		p {
// 			width: 100%;
// 		}

// 		input,
// 		a {
// 			background-color: $color4;
// 			color: #fff;
// 			border-radius: 42px;
// 			text-decoration: none;
// 			// width: 160px;

// 			border: 1px solid transparent;
// 			display: inline-block;
// 			font-weight: 700;
// 			padding: 10px 70px;
// 			text-align: center;
// 			-webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
// 			transition: color 0.3s, background-color 0.3s, border-color 0.3s;
// 			cursor: pointer;

// 			&:hover,
// 			&:focus {
// 				color: #171717;
// 				background: none;
// 				border-color: $color4;
// 			}

// 			&.close {
// 				position: absolute;
// 				top: 0;
// 				width: 43px;
// 				height: 43px;
// 				left: -6px;
// 				background-color: #d3552c;
// 				border-radius: 0 0 25px;
// 				border: none;
// 				font-size: 1.3rem;
// 				padding: 0 0 0 5px;
// 				transform: none;
// 				font-weight: bolder;
// 				color: $color4;
// 			}
// 		}

// 		.icon_close {
// 			position: absolute;
// 			top: 15px;
// 			left: 15px;
// 			fill: #d3552c;
// 			scale: 1.6;
// 			cursor: pointer;
// 			transition: scale 0.2s ease-in-out;

// 			&:hover {
// 				scale: 1.8;
// 			}
// 		}

// 		h1 {
// 			font-size: 1.8rem;
// 			font-weight: 700;
// 			color: #d3552c;
// 			margin-bottom: 20px;
// 			text-align: center;

// 			&:first-letter {
// 				text-transform: uppercase;
// 			}
// 		}

// 		.tag_container {
// 			width: 100%;
// 			display: flex;

// 			justify-content: flex-start;
// 			flex-wrap: wrap;
// 			align-items: center;
// 			gap: 10px 15px;

// 			li {
// 				padding: 6px 10px;
// 				line-height: 1rem;
// 				border-radius: 12px;
// 				font-size: 0.9rem;
// 				background-color: $color4;
// 				color: white;
// 			}
// 		}

// 		#imagePlayer {
// 			width: 100%;
// 			height: 100%;
// 			max-height: 200px;
// 			border: 1px solid $color4;
// 			object-fit: cover;
// 		}

// 		.details_container {
// 			width: 100%;

// 			span {
// 				font-size: 18px;
// 				font-weight: bolder;

// 				&.lang {
// 					text-transform: uppercase;
// 					font-weight: initial;
// 				}
// 			}
// 		}
// 	}
// }

.sidebar_container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;

	.sidebar {
		width: 80%;
		height: 100%;
		transform: translateX(100%);
		visibility: hidden;
		z-index: 1;

		background-color: rgba($color: $color1, $alpha: 0.8);
		-webkit-box-shadow: -6px 4px 12px 0px $color2;
		box-shadow: -6px 4px 12px 0px $color2;
		border-radius: 30px 0 0 30px;
		padding: 30px;
		overflow: hidden;
		gap: 15px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&.active {
			transform: translateX(0);
			visibility: visible;
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			height: 100%;
		}

		p {
			width: 100%;
		}

		input,
		a {
			background-color: $color4;
			color: #fff;
			border-radius: 42px;
			text-decoration: none;
			// width: 160px;

			border: 1px solid transparent;
			display: inline-block;
			font-weight: 700;
			padding: 10px 70px;
			text-align: center;
			-webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
			transition: color 0.3s, background-color 0.3s, border-color 0.3s;
			cursor: pointer;

			&:hover,
			&:focus {
				color: #171717;
				background: none;
				border-color: $color4;
			}

			&.close {
				position: absolute;
				top: 0;
				width: 43px;
				height: 43px;
				left: -6px;
				background-color: #d3552c;
				border-radius: 0 0 25px;
				border: none;
				font-size: 1.3rem;
				padding: 0 0 0 5px;
				transform: none;
				font-weight: bolder;
				color: $color4;
			}
		}

		.icon_close {
			position: absolute;
			top: 15px;
			left: 15px;
			fill: #d3552c;
			scale: 1.6;
			cursor: pointer;
			transition: scale 0.2s ease-in-out;

			&:hover {
				scale: 1.8;
			}
		}

		h1 {
			font-size: 1.8rem;
			font-weight: 700;
			color: $color2;
			margin-bottom: 30px;
			text-align: center;

			&:first-letter {
				text-transform: uppercase;
			}
		}

		.tag_container {
			width: 100%;
			display: flex;

			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px 15px;

			li {
				padding: 6px 10px;
				line-height: 1rem;
				border-radius: 12px;
				font-size: 0.9rem;
				background-color: $color4;
				color: $color1;
			}
		}

		#imagePlayer {
			width: 100%;
			height: 100%;
			max-height: 25vh;
			border: 1px solid $color4;
			object-fit: cover;
		}

		.details_container {
			width: 100%;

			span {
				font-size: 18px;
				font-weight: bolder;

				&.lang {
					text-transform: uppercase;
					font-weight: initial;
				}
			}
		}
	}
}
