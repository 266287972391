@import "../../_variable";

.swiping-sidebar {
	height: 100%;
	width: 40%;
}
.swiping-iframe {
	height: 100%;
	width: 100%;
}
