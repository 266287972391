// Hamak
// $color1: white;
// $color2: #00acec;
// $color3: #31a459;
// $color4: #222121;
// $color4: rgb(0, 0, 0);

// MK
$color1: white;
$color2: #eb6c41;
$color3: #eb6c41;
$color4: #222121;
$color4: rgb(0, 0, 0);
