@import "../../_variable";

// .carousel_container {
// 	width: 50vw;
// 	height: 100%;
// 	// transform: translateY(-50%);
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	align-items: center;

// 	.swiper {
// 		width: 100%;
// 		height: 100%;
// 		z-index: 0;

// 		.swiper-wrapper {
// 			align-items: center;

// 			.swiper-slide {
// 				width: fit-content;
// 			}
// 		}
// 	}
// }

.carousel_container {
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.swiper {
		width: 100%;
		height: 100%;
		z-index: 0;

		.swiper-wrapper {
			align-items: center;

			.swiper-slide {
				width: fit-content;
			}
		}
	}
}
