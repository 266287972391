@import "../../_variable";

// .home {
// 	background-image: url("../../assets/images/bg_hamak2.webp");
// 	background-repeat: no-repeat;
// 	background-position: center;
// 	background-size: cover;
// 	width: 100%;
// 	height: 100vh;
// 	overflow: hidden;

// 	font-family: "degular", sans-serif;
// 	font-weight: 400;
// 	font-style: normal;

// 	.home_modal {
// 		height: 100%;
// 		width: 50%;
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		bottom: 0;
// 	}

// 	.side_container {
// 		position: absolute;
// 		top: 0;
// 		bottom: 0;
// 		right: 0;
// 		transform: translateX(105%);
// 		display: flex;
// 		transition: all 0.5s ease-in-out;

// 		&.one {
// 			transform: translateX(71%);
// 		}

// 		&.two {
// 			transform: translateX(0);
// 		}
// 	}

// 	input.switch {
// 		background: url("../../assets/images/button.png") no-repeat center / contain;
// 		position: absolute;
// 		bottom: 166.6px;
// 		left: 33.1px;
// 		width: 55px;
// 		height: 55px;
// 		z-index: 3000;
// 		border-radius: 50%;
// 		border: none;
// 		// transition: border .3s ease-in-out;

// 		&.active {
// 			border: 3px solid white;
// 		}

// 		&.two {
// 			bottom: 58.6px;
// 			left: 33.1px;
// 		}
// 	}
// }

.home_container {
	width: 200vw;
	height: 100vh;
	overflow: hidden;
	position: relative;

	&.active {
		transform: translateX(-100vw);
	}

	font-family: "degular", sans-serif;
	font-weight: 400;
	font-style: normal;
	display: flex;
	flex-direction: row;

	.wrapper {
		background-image: url("../../assets/images/bg_mk.jpg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 100vw;
		height: 100%;
		display: flex;
	}
	.button_container {
		position: absolute;
		z-index: 3000;
		bottom: 30px;
		left: 30px;
		flex-direction: column;
		gap: 20px;

		display: flex;

		input.switch {
			background: url("../../assets/images/button.png") no-repeat center / contain;
			border-radius: 50%;
			border: none;
			height: 50px;
			width: 50px;
			&.active {
				border: 3px solid $color1;
			}

			&.two {
				bottom: 130.6px;
			}

			&.three {
				bottom: 58.6px;
			}
		}
	}
}
