@import "../../_variable";

// .carousel_item_container {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: center;
//     gap: 10px;
//     color: rgb(0, 0, 0);
//     font-weight: bolder;
//     font-size: 1.8rem;
//     opacity: .6;
//     transition: all .6s ease-in-out;
//     cursor: pointer;
//     width: 300px;
//     text-align: center;
//     &.hamak {
//         &>p {
//             background-color: #00acec;
//             padding: 5px;
//             color: white;
//         }
//     }
//     .image_container {
//         border: 5px solid white;
//         width: 300px;
//         height: 150px;
//         transition: opacity .5s ease-in-out;
//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//     }
//     &>p {
//         background-color: #d3552c;
//         padding: 5px;
//         color: white;
//     }
//     &.active {
//         opacity: 1;
//         transform: translateX(100px);
//     }
// }
.carousel_item_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	color: $color4;
	font-weight: bolder;
	font-size: 1.8rem;
	cursor: pointer;
	text-align: center;

	.image_container {
		border: 5px solid $color1;
		width: 300px;
		height: 150px;
		transition: opacity 0.5s ease-in-out;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	& > p {
		background-color: $color2;
		padding: 5px;
		color: $color1;
	}
}
